<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <Navbar />
        <v-row justify="center" class="ma-0">
            <v-col cols=12 md=6>
            <v-card :class="[$vuetify.breakpoint.smAndDown ? 'px-5' : 'px-10', 'py-10', 'mx-3']" flat style="border-radius: 20px">
                <div class="primary--text display-1 ml-5 mb-5 font-weight-medium">{{ $t("passwordRecovery.recover") }}</div>
                <div class="body grey--text text--darken-2 mb-3 ml-2">{{ $t("passwordRecovery.inputEmail") }}</div>
                <v-form :class="[$vuetify.breakpoint.smAndDown ? 'px-0' : 'px-3']" ref="recoveryForm" @submit.prevent="recover">
                    <InputField 
                        rules="emailTest"
                        :label="$t('passwordRecovery.email')"
                        icon="email"
                        v-model="email"
                    />
                    <v-row justify="center">
                        <span v-if="messageSend" class="body grey--text text--darken-2 mb-5 ml-2">{{ $t("passwordRecovery.messageSent") }}</span>
                        <v-btn :loading="loading" depressed color="primary" class="mx-0 px-5" type="submit">{{ $t("passwordRecovery.sendEmail") }}</v-btn>
                    </v-row>
                </v-form>
            </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'
import PopupDialog from '@/components/partials/PopupDialog'
import InputField from '@/components/partials/InputField'
import axios from 'axios'
    export default {
        name: "PasswordRecoveryForm",
        components: { Navbar, InputField, PopupDialog },
        data() {
            return {
                email: '',
                dialog: false,
                loading: false,
                messageSend: false
            }
        },
        methods: {
            recover: function() {
                if (this.$refs.recoveryForm.validate()) {
                    let data_to_post = {
                        email: this.email
                    }
                    this.loading = true
                    axios.post('/reset_password_email', data_to_post)
                    .then(() => {
                        this.loading = false
                        this.messageSend = true
                    })
                    .catch(() => {
                        this.dialog = true
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>